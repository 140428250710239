import React, { useEffect } from "react";
import "../../pages/services/services.css";

import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { updateCart } from "../../actions";

import { TextField } from "@mui/material";
import { useState } from "react";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";

function Donations() {
  const params = useParams();
  const [donationData, setDonationData] = useState();
  const cartArr = useSelector((state) => state.cartArr);
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("token");
  const [donationAmount, setDonationAmount] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const getDonations = async () => {
    const response = await postApi(apiEndPoints.getDonationByCategory, {
      id: params.donationcategory,
    });
    if (response && response.status === 200) {
      setDonationData(response?.data?.data);
    }
  };

  useEffect(() => {
    getDonations();
  }, [params]);

  const handleAddDonations = (item) => {
    const amount = donationAmount[item._id];

    // Check if the donation amount is entered and valid
    if (!amount || amount < item.amount) {
      setErrorMessage(
        !amount
          ? "Please enter a donation amount"
          : `Minimum donation amount is $${item.amount}`
      );
      return; // Prevent adding to cart if validation fails
    }

    // Add the donation to the cart if valid
    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        amount: donationAmount[item._id],
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        amount: donationAmount[item._id],
      };
      dispatch(updateCart(newItem));
    }

    setErrorMessage(""); // Clear the error message after successful addition
  };

  const handleDonationAmount = (item, e) => {
    const value = e.target.value;

    if (value === "" || value < item.amount) {
      // Show error if no value is entered or it's less than the minimum
      setErrorMessage(
        value === ""
          ? "Please enter a donation amount"
          : `Minimum donation amount is $${item.amount}`
      );
    } else {
      // Clear error if valid amount is entered
      setErrorMessage("");
    }

    setDonationAmount((prev) => ({
      ...prev,
      [item._id]: value,
    }));
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Donation</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Services</Link>
          </li>
          <li className="breadcrumb-item active">Donation</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Donation</span>
          </h1>
        </div>

        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="row room-item m-0 mb-4">
              <div className="col-md-4 col-lg-12 col-xl-4 p-0 img-ser-detail">
                <div className="position-relative h-100">
                  <img
                    className="position-absolute img-ser-detail"
                    src={donationData?.image}
                    alt={donationData?.title}
                  />
                </div>
              </div>
              <div className="col-md-8 col-lg-12 col-xl-8 h-100 px-0">
                <div className="p-4">
                  <div className="d-flex align-donationData-center justify-content-between">
                    <h5 className="service-title">{donationData?.title}</h5>
                  </div>
                  <div className="d-flex justify-content-between border-bottom mt-auto pb-3">
                    <TextField
                      className="me-2"
                      value={donationAmount[donationData?._id] || ""}
                      onChange={(e) => handleDonationAmount(donationData, e)}
                      label="Donation in $"
                      id="outlined-size-normal"
                      error={!!errorMessage} // Show error
                      helperText={errorMessage} // Show error message
                    />

                    <button
                      className="btn btn2 btn-dark rounded py-2 px-4"
                      onClick={() => handleAddDonations(donationData)}
                      disabled={!!errorMessage}
                    >
                      Add to Basket
                    </button>
                  </div>

                  <p className="text-body mb-0 mt-3">
                    {donationData?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* basket ------------------------------------------- */}
          <div className="col-lg-3 col-md-4">
            <PoojaBasket />
          </div>
        </div>
      </div>
    </>
  );
}

export default Donations;
